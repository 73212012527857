import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState, useMemo, useEffect, useContext, useCallback } from 'react';

import { useDarkMode } from '@Hub/Header/utils/hooks';

import { APIBaseUrlContext, ProjectContext } from '@core/context';
import useReadmeApi, { fetchWrapper } from '@core/hooks/deprecated/useReadmeApi';
import useMediaQuery from '@core/hooks/useMediaQuery';
import usePrevious from '@core/hooks/usePrevious';
import useRetry from '@core/hooks/useRetry';
import useTimezone from '@core/hooks/useTimezone';
import { useReferenceStore } from '@core/store';
import { stringifyOptions } from '@core/store/Realtime';

import classes from './style.module.scss';

const APILogsGraph = loadable(() => import('@ui/API/LogsGraph'), { ssr: false });

function GraphContainer({ ephemeralHAR, isLoggedIn, method, url, myRequestsEnabled }) {
  const timezone = useTimezone() || 'UTC';
  const apiBaseUrl = useContext(APIBaseUrlContext);
  const { project } = useContext(ProjectContext);

  const groupId = useReferenceStore(s => s.auth.hashedGroup);

  const userScheme = useDarkMode();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const queryParamString = useMemo(() => {
    // Note: we want 4 buckets for the graph, so we use a rangeLength of 4 weeks
    return qs.stringify(
      {
        subdomain: project.subdomain,
        resolution: 'week',
        rangeLength: 4,
        groupId,
        method,
        url,
        groupBy: ['period', 'success'],
      },
      stringifyOptions,
    );
  }, [method, groupId, project.subdomain, url]);

  const [metricsResponse, setMetricsResponse] = useState();

  const prevEphemeralHAR = usePrevious(ephemeralHAR);
  const isReady = prevEphemeralHAR != null && ephemeralHAR && ephemeralHAR !== prevEphemeralHAR;

  const pollMetrics = useCallback(() => {
    return fetchWrapper(apiBaseUrl, {
      path: `api/realtime/requests?${queryParamString}`,
      headers: {
        'x-timezone': timezone,
      },
    });
  }, [apiBaseUrl, queryParamString, timezone]);

  const handlePollMetrics = useCallback(
    nextMetricsResponse => {
      const isAccepted =
        (nextMetricsResponse && metricsResponse && nextMetricsResponse.total > metricsResponse.total) || false;

      if (isAccepted) {
        setMetricsResponse(nextMetricsResponse);
      }

      return isAccepted;
    },
    [metricsResponse],
  );

  // Whenever a user clicks the Try It Now button the response that is passed through will be updated and reflected in the `isReady`
  // We then use this hook to perform an exponential backoff of `pollMetrics`. Finally `handlePollMetrics` checks the result and
  // either will use it if the data is fresh or will return false to tell the retry mechanism to continue retrying.
  useRetry({ isReady, action: pollMetrics, callback: handlePollMetrics });

  const { initRequest, response: statusCodesResponse, isLoading } = useReadmeApi(apiBaseUrl, null);

  useEffect(() => {
    if (statusCodesResponse) setMetricsResponse(statusCodesResponse);
  }, [statusCodesResponse]);

  useEffect(() => {
    if (!groupId || !method) {
      // Wait for it to be defined
      return;
    }

    initRequest({
      path: `api/realtime/requests?${queryParamString}`,
      headers: {
        'x-timezone': timezone,
      },
    });
  }, [groupId, initRequest, method, queryParamString, timezone]);

  return (
    <APILogsGraph
      className={classes.GraphContainer}
      data={metricsResponse}
      isLoading={isLoading}
      isLoggedIn={isLoggedIn}
      myRequestsEnabled={myRequestsEnabled}
      useDarkMode={userScheme === 'dark' || (userScheme === 'system' && prefersDarkMode)}
    />
  );
}

GraphContainer.propTypes = {
  ephemeralHAR: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isLoggedIn: PropTypes.bool,
  method: PropTypes.string,
  myRequestsEnabled: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

export default GraphContainer;
