import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useState, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import { APIBaseUrlContext } from '@core/context';
import useReadmeApi, { fetchWrapper } from '@core/hooks/deprecated/useReadmeApi';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useMetrics from '@core/hooks/useMetrics';
import usePrevious from '@core/hooks/usePrevious';
import useRetry from '@core/hooks/useRetry';
import useTimezone from '@core/hooks/useTimezone';
import { useReferenceStore } from '@core/store';

import { MockHarContext } from '@routes/Reference/context/HARContext';

import SharedLogErrorModal from './SharedLogErrorModal';

const APILogsTable = loadable(() => import('@ui/API/LogsTable'), { ssr: false });
// Only displaying the first 3 results from first page
const PAGE_SIZE = 3;
const PAGE = 0;

// RM-1362 Only used when mimicing metrics for no auth scenario
const mimicPaginate = (array, pageSize, pageNumber) =>
  array.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize);

function TableContainer({ ephemeralHAR, method, onSelectLog, selectedHarId = null, url: queryUrl, footer }) {
  const { isServer } = useEnvInfo();
  const timezone = useTimezone() || 'UTC';

  const apiBaseUrl = useContext(APIBaseUrlContext);
  const { shouldUseMockHars, mockHars } = useContext(MockHarContext);
  const groupId = useReferenceStore(s => s.auth.hashedGroup);

  const { isDevDashEnabled } = useMetrics();

  const [logs, setLogs] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const firstLogsResponse = useRef(null);
  const errorModalRef = useRef(false);

  useEffect(() => {
    setSelectedId(selectedHarId);
  }, [selectedHarId]);

  const {
    initRequest: initRecentRequest,
    response: logsResponse,
    isLoading,
  } = useReadmeApi(apiBaseUrl, [], shouldUseMockHars);
  const { initRequest: initShowLogRequest, response: logResponse } = useReadmeApi(apiBaseUrl, null, shouldUseMockHars);

  // A featured log is usually one that has been shared to a user
  const {
    initRequest: initSharedLog,
    response: featuredLogResponse,
    error: sharedLogNotFoundError,
  } = useReadmeApi(apiBaseUrl, null, shouldUseMockHars);

  const paginatedMockHars = useMemo(
    () => (shouldUseMockHars ? mimicPaginate(mockHars, PAGE_SIZE, PAGE) : []),
    [mockHars, shouldUseMockHars],
  );

  // Reset logs on unmount
  useEffect(() => {
    return () => {
      setLogs([]);
    };
  }, [queryUrl, method]);

  useEffect(() => {
    if (isServer) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const metricId = params.get('metricId'); // legacy
    const shareId = params.get('shareId');

    if (shareId != null) {
      initSharedLog({ path: `api/metricshare/${shareId}` });
    } else if (metricId != null) {
      const query = qs.stringify({ groupId });
      initSharedLog({ path: `api/logs/${metricId}?${query}` });
    }
  }, [isServer, initSharedLog, groupId]);

  useEffect(() => {
    if (sharedLogNotFoundError) errorModalRef.current?.toggle(true);
  }, [sharedLogNotFoundError]);

  useEffect(() => {
    const response = logsResponse.logs ?? [];
    setLogs(response);
    if (!firstLogsResponse.current?.length) {
      firstLogsResponse.current = response[0];
    }
  }, [logsResponse]);

  const prevEphemeralHAR = usePrevious(ephemeralHAR);
  const isReady = !shouldUseMockHars && prevEphemeralHAR != null && ephemeralHAR && ephemeralHAR !== prevEphemeralHAR;

  function pollMetrics() {
    const query = qs.stringify({
      groupId,
      method,
      // Get data from the first page
      page: PAGE,
      pageSize: PAGE_SIZE,
      rangeLength: isDevDashEnabled ? 30 : 24,
      resolution: isDevDashEnabled ? 'day' : 'hour',
      url: queryUrl,
    });

    return fetchWrapper(apiBaseUrl, {
      path: `api/realtime/recent?${query}`,
      headers: {
        'x-timezone': timezone,
      },
    });
  }

  function handlePollMetrics({ logs: nextLogs }) {
    const isAccepted =
      nextLogs.length > logs.length || (nextLogs.length && logs.length && nextLogs[0].id !== logs[0].id);

    if (isAccepted) {
      setLogs(nextLogs);
    }

    return isAccepted;
  }

  // Whenever a user clicks the Try It Now button the response that is passed through will be updated and reflected in the `isReady`
  // We then use this hook to perform an exponential backoff of `pollMetrics`. Finally `handlePollMetrics` checks the result and
  // either will use it if the data is fresh or will return false to tell the retry mechanism to continue retrying.
  useRetry({ isReady, action: pollMetrics, callback: handlePollMetrics });

  useEffect(() => {
    if (!groupId || !method) {
      // Wait for it to be defined
      return;
    }

    const queryParams = qs.stringify({
      groupId,
      method,
      page: PAGE,
      pageSize: PAGE_SIZE,
      rangeLength: isDevDashEnabled ? 30 : 24,
      resolution: isDevDashEnabled ? 'day' : 'hour',
      url: queryUrl,
    });

    initRecentRequest({
      path: `api/realtime/recent?${queryParams}`,
      headers: {
        'x-timezone': timezone,
      },
    });
  }, [initRecentRequest, isDevDashEnabled, groupId, queryUrl, timezone, method]);

  const handleRowSelect = useCallback(
    (id, log) => {
      setSelectedId(id);

      if (id == null) {
        // Unselecting the row
        onSelectLog({ id: null });
        return;
      }

      // RM-1362 Mock metrics for operations with no auth
      if (log?.isMock) {
        onSelectLog({ id, response: { log } });
        return;
      }

      const createdAt = log?.createdAt ? new Date(log.createdAt).toISOString() : undefined;
      const query = qs.stringify({ groupId, createdAt });

      initShowLogRequest({
        path: `api/logs/${id}?${query}`,
        headers: {
          'x-timezone': timezone,
        },
      });
    },
    [groupId, initShowLogRequest, onSelectLog, timezone],
  );

  useEffect(() => {
    const res = shouldUseMockHars
      ? mockHars.find(h => h.id === selectedId)?.request
      : logResponse || featuredLogResponse;

    onSelectLog({ id: selectedId, response: res });
  }, [selectedId, logResponse, featuredLogResponse, onSelectLog, shouldUseMockHars, mockHars]);

  useEffect(() => {
    if (featuredLogResponse == null) return;
    onSelectLog({ id: featuredLogResponse?.log?.id, response: featuredLogResponse });
  }, [featuredLogResponse, onSelectLog]);

  useEffect(() => {
    // If the most recent log after a logs update came from the API explorer,
    // it should be the result of the user clicking "Try It", so select it in the table.
    const firstLog = shouldUseMockHars ? paginatedMockHars[0] : logs[0];
    if (firstLog?.useragent === 'ReadMe-API-Explorer' && firstLogsResponse.current?.id !== firstLog?.id) {
      handleRowSelect(firstLog.id, firstLog);
    }
  }, [firstLogsResponse, handleRowSelect, logs, paginatedMockHars, shouldUseMockHars]);

  return (
    <>
      <APILogsTable
        key={`table-${queryUrl}-${method}`}
        featuredLog={featuredLogResponse?.log}
        footer={footer}
        isLoading={isLoading}
        logs={shouldUseMockHars ? paginatedMockHars : logs}
        onRowSelect={handleRowSelect}
        selectedRowId={selectedId}
      />
      <SharedLogErrorModal ref={errorModalRef} />
    </>
  );
}
TableContainer.propTypes = {
  ephemeralHAR: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  footer: PropTypes.node,
  method: PropTypes.string,
  onSelectLog: PropTypes.func.isRequired,
  selectedHarId: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default TableContainer;
