import type {
  ApiKeyProps,
  AuthInputProps,
  BaseAuthInputProps,
  BasicAuthProps,
  GroupBadgeProps,
  GroupDropdownProps,
  GroupItemProps,
} from '../../types';
import type { OpenAPIV3 } from 'openapi-types';

import { getGroupNameById } from '@readme/iso/src/metrics';
import React from 'react';

import Box from '@ui/Box';
import Dropdown from '@ui/Dropdown';
import Menu from '@ui/Menu';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';
import RDMD from '@ui/RDMD';
import Tooltip from '@ui/Tooltip';

import { InfoTooltip } from '../../components/Info';
import setInputRef from '../../set-input-ref';
import useShowPasswordToggle from '../../useShowPasswordToggle';
import classes from '../style.module.scss';

const labels: Record<string, BaseAuthInputProps['label']> = {
  apiKey: { placeholder: 'X-API-KEY' },
  basic: { placeholder: 'username', placeholder2: 'password', separator: ':' },
  bearer: { placeholder: 'token' },
  oauth2: { placeholder: 'token', prefix: 'Bearer' },
};

const GroupItem = ({ name }: GroupItemProps) => <div className="InputGroup-GroupItem">{name}</div>;

const ApiKey = ({ apiKey, change, inputIndex, inputRef, label, placeholder }: ApiKeyProps) => {
  const { showPassword, ShowPasswordToggle } = useShowPasswordToggle();

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    change(e.target.value);
  }

  return (
    <>
      {!!label.prefix && (
        <label className="InputGroup-prefix" htmlFor={`APIAuth-${placeholder || label.placeholder}`}>
          {label.prefix}
        </label>
      )}
      <input
        ref={el => setInputRef(el, inputIndex, inputRef)}
        autoComplete="off"
        className={['InputGroup-input', label.separator ? 'before-separator' : ''].join(' ')}
        id={`APIAuth-${placeholder || label.placeholder}`}
        onChange={onInputChange}
        placeholder={placeholder || label.placeholder}
        required
        spellCheck="false"
        type={showPassword ? 'text' : 'password'}
        value={typeof apiKey === 'string' ? apiKey : ''}
      />
      {ShowPasswordToggle}
    </>
  );
};

const Basic = ({ change, inputIndex, inputRef, label, pass, user }: BasicAuthProps) => {
  const { showPassword, ShowPasswordToggle } = useShowPasswordToggle();

  function inputChange(name: string, val: string) {
    change({ user, pass, [name]: val });
  }

  return (
    <>
      <input
        ref={el => setInputRef(el, inputIndex, inputRef)}
        aria-label={label.placeholder}
        autoComplete="off"
        className={['InputGroup-input', label.separator ? 'before-separator' : ''].join(' ')}
        data-1p-ignore
        name="user"
        onChange={e => inputChange(e.target.name, e.target.value)}
        placeholder={label.placeholder}
        required
        spellCheck="false"
        type={showPassword ? 'text' : 'password'}
        value={user || ''}
      />
      <span className="InputGroup-prefix">{label.separator}</span>
      <input
        aria-label={label.placeholder2}
        autoComplete="off"
        className="InputGroup-input"
        name="pass"
        onChange={e => inputChange(e.target.name, e.target.value)}
        placeholder={label.placeholder2}
        spellCheck="false"
        type={showPassword ? 'text' : 'password'}
        value={pass || ''}
      />
      {ShowPasswordToggle}
    </>
  );
};

const GroupBadge = ({ group, groups }: GroupBadgeProps) => {
  const groupNameMatch = getGroupNameById(groups, group);
  const name = groupNameMatch || group;
  const content = <RDMD>{`Authentication credentials for\n\`${name}\``}</RDMD>;

  return (
    <Tooltip content={content}>
      <div className="InputGroup-badge" title={name}>
        <GroupItem name={name} />
      </div>
    </Tooltip>
  );
};

const GroupDropdown = ({ group, groups, onSelect }: GroupDropdownProps) => {
  if (groups && groups.length)
    return (
      <Menu>
        <MenuHeader className={classes['APIAuth-menu-header']}>Select Credentials</MenuHeader>
        {groups.map(item => (
          <MenuItem
            key={item.id}
            aria-selected={group === item.id}
            className={classes['APIAuth-menu-item']}
            onClick={() => onSelect(item.id)}
            role="option"
          >
            <GroupItem name={item.name || item.id} />
          </MenuItem>
        ))}
      </Menu>
    );
  return null;
};

function AuthInput(props: AuthInputProps) {
  const { auth, displayTooltip, group, groups, inputIndex, inputRef, onAuthGroupChange, onChange, security } = props;
  let input;
  const scheme = security.security;
  const change = value => onChange({ [scheme._key]: value });

  // Because OAS validation doesn't throw when `scheme` is here when it shouldn't be, there's a possibility that it's
  // here and also an invalid value. If we have it, but don't recognize it we should always fallback to the set `type`
  // so as to not kill the page when attempting later to look for `label.placeholder` and `label.prefix`.
  const label =
    'scheme' in scheme && scheme.scheme && scheme.scheme in labels ? labels[scheme.scheme] : labels[scheme.type];

  const defaultProps = { change, inputIndex, inputRef, label } as const;

  switch (scheme.type) {
    case 'apiKey':
    case 'oauth2':
      input = (
        <ApiKey
          apiKey={auth[scheme._key] as string}
          placeholder={(scheme as OpenAPIV3.ApiKeySecurityScheme).name}
          {...defaultProps}
        />
      );
      break;
    case 'http':
      if (scheme.scheme === 'basic') {
        const currentAuth = auth[scheme._key];
        const user =
          !!currentAuth && typeof currentAuth === 'object' && typeof currentAuth.user === 'string'
            ? currentAuth.user
            : '';
        const pass =
          !!currentAuth && typeof currentAuth === 'object' && typeof currentAuth.pass === 'string'
            ? currentAuth.pass
            : '';

        input = <Basic pass={pass} user={user} {...defaultProps} />;
      }
      if (scheme.scheme === 'bearer') input = <ApiKey apiKey={auth[scheme._key] as string} {...defaultProps} />;
      break;
    default:
      return null;
  }

  const InputFieldWrapper = (
    <div className="InputGroup-dropdown-inputs">
      {input}
      {!!displayTooltip && <InfoTooltip security={security} showBadges />}
      {!!group && !!groups && groups.length > 0 && <GroupBadge group={group} groups={groups} />}
    </div>
  );

  return (
    <div className="InputGroup-input-parent">
      {/* Don't render the dropdown if a user is logged in with a single auth group */}
      {group && (!groups || groups.length === 0) ? (
        InputFieldWrapper
      ) : (
        <>
          <Dropdown className="InputGroup-dropdown" clickInToClose trigger="click">
            {React.cloneElement(InputFieldWrapper, { role: 'combobox' })}
            <Box kind="pop" style={{ padding: 0, width: 'max-content' }}>
              <GroupDropdown group={group} groups={groups} onSelect={onAuthGroupChange} />
            </Box>
          </Dropdown>
        </>
      )}
    </div>
  );
}

export default React.memo(AuthInput);
