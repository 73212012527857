// Various functions for handling the interactions
// between API Metrics and OpenAPI documents.

import type { Entry, Har } from 'har-format';
import type Oas from 'oas';
import type { Operation } from 'oas/operation';

import { v4 as uuid } from 'uuid';

import { processTryItRequest } from './metrics/zero-config';

function definedHeaders(logHeaders, allowableHeaders) {
  return logHeaders.filter(h => allowableHeaders.includes(h.name.toLowerCase()));
}

function undefinedHeaders(logHeaders, allowableHeaders) {
  return logHeaders.filter(h => !allowableHeaders.includes(h.name.toLowerCase()));
}

/* eslint-disable no-param-reassign */
/**
 * @see {@link https://npm.im/oas}
 * @param {object} log
 * @param {Operation} operation An already fully dereferenced instance of an Operation from the `oas` library.
 */
export function sortHeaders(log, operation: Operation) {
  const allowedHeaders = operation.getHeaders();
  // Normalize everything to lowercase
  allowedHeaders.request = allowedHeaders.request.map(r => r.toLowerCase());
  allowedHeaders.response = allowedHeaders.response.map(r => r.toLowerCase());
  // Prioritize headers based on what is and isn't in the OpenAPI document
  log.fullReqHeaders = undefinedHeaders(log.requestHeaders, allowedHeaders.request);
  log.requestHeaders = definedHeaders(log.requestHeaders, allowedHeaders.request);
  log.fullResHeaders = undefinedHeaders(log.responseHeaders, allowedHeaders.response);
  log.responseHeaders = definedHeaders(log.responseHeaders, allowedHeaders.response);

  // In the HAR object inside the log object, overwrite the full header list
  // with the list of defined headers. The HAR object is used for generating cURL,
  // so this is so the cURL request only displays prioritized headers.
  log.request.log.entries[0].request.headers = log.requestHeaders;
  return log;
}

export function applyProxy(log, proxyEnabled?: boolean) {
  const url = log?.entries[0]?.request?.url;

  if (proxyEnabled && url) {
    log.entries[0].request.url = `https://try.readme.io/${url}`;
  }

  return log;
}
/* eslint-enable no-param-reassign */

/**
 * @param {object} log
 */
export function findOperation(log, oasInstanceList: Oas[], options: { resolveWithoutMethod?: boolean } = {}) {
  const resolveWithoutMethod = options && options.resolveWithoutMethod;
  let logOperation;
  let index: number | undefined;
  let modifiedLog;

  // RM-976 Workaround to treat readme.io metrics as if the are from readme.com
  try {
    const url = new URL(log.url);
    // Check if this is a readme.io log, convert it to readme.com given the Oas file currently only includes .com
    if (/readme.io/.test(url.host)) {
      // Deep copy the log
      modifiedLog = JSON.parse(JSON.stringify(log));
      const splitHost = url.host.split('.');
      // Get rid of the `.io` & append `.com`
      splitHost.pop();
      url.host = splitHost.join('.').concat('.com');
      modifiedLog.url = url.href;
      modifiedLog.request.log.entries.forEach(entry => {
        // eslint-disable-next-line no-param-reassign
        entry.request.url = url.href;
      });
    }
  } catch (e) {
    // Do nothing
  }

  oasInstanceList.forEach((instance, i) => {
    // if the url is well formed - try to resolve it
    try {
      const url = modifiedLog ? modifiedLog.url : log.url;
      const targetOperation = resolveWithoutMethod
        ? instance.findOperationWithoutMethod(url)
        : instance.findOperation(url, log.method);
      if (targetOperation) {
        logOperation = targetOperation;
        index = i;
      }
    } catch (err) {
      // do nothing
    }
  });
  return { logOperation, index };
}

export function groupEndpoints(endpoints, oasInstanceList: Oas[], groupingOptions?: { pathOnly?: boolean }) {
  const grouping = {};
  const options = { resolveWithoutMethod: true };
  const pathOnly = groupingOptions && groupingOptions.pathOnly;

  endpoints.forEach(({ url, method, count }) => {
    const operation = findOperation({ url, method }, oasInstanceList, options).logOperation;
    if (!operation) return true;

    const path = operation.url.path
      .split('/')
      .map(piece => {
        if (piece[0] === ':') return `{${piece.substr(1)}}`;
        return piece;
      })
      .join('/');

    let operationUrl = pathOnly ? path : `${operation.url.origin}${path}`;

    if (operation.operation.parameters) {
      const queryParams = operation.operation.parameters.filter(param => param.in === 'query');
      if (queryParams.length) {
        const queryParamString = queryParams.map(param => `${param.name}={${param.name}}`).join('&');
        operationUrl = `${operationUrl}?${queryParamString}`;
      }
    }

    if (!grouping[operationUrl]) {
      grouping[operationUrl] = parseInt(count, 10);
    } else {
      grouping[operationUrl] += parseInt(count, 10);
    }
    return true;
  });
  return Object.keys(grouping).map(url => {
    return {
      url,
      count: grouping[url],
    };
  });
}

// RM-1362 Support operations with no auth by mocking responses client-side
export async function mockMetricsResponse(subdomain: string, har: Har, response) {
  const request = har?.log?.entries?.[0]?.request;
  const { headers: requestHeaders, queryString, url, method } = request;
  const group = { id: 'unknown', label: 'unknown', email: 'unknown' };
  const { log: mockHar } = await processTryItRequest(subdomain, { request } as Entry, group, response);
  const { headers: responseHeaders, content: responseBody } = mockHar.request?.log?.entries?.[0]?.response || {};
  const { status } = response;
  const now = new Date().toString();

  // Strip any try.readme.io information from this mock request as we don't surface that domain
  // in any logs coming out of Metrics.
  let sanitizedUrl = url;
  if (url.startsWith('https://try.readme.io/')) {
    sanitizedUrl = url.replace('https://try.readme.io/', '');
    if (mockHar.request?.log?.entries?.[0]?.request) {
      mockHar.request.log.entries[0].request.url = sanitizedUrl;
    }
  }

  return {
    ...mockHar,
    queryString,
    requestHeaders,
    responseHeaders,
    responseBody,
    status,
    method,
    url: sanitizedUrl,
    useragent: {
      name: 'ReadMe-API-Explorer',
      platform: 'ReadMe',
    },
    id: uuid(),
    startedDateTime: now,
    createdAt: now,
    isMock: true,
  };
}
