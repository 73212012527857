import type { InfoBadgeParentProps, InfoBadgeProps, InfoTooltipProps } from '../../types';

import React from 'react';

import InfoPop from '@ui/InfoPop';
import RDMD from '@ui/RDMD';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

const rdmdOpts = { copyButtons: false };

const tooltipContentMap = {
  base64: 'Your username and password will be combined with a : to form a base64-encoded string: `ENCODED_TOKEN`',
  basic:
    'Your username and password are being sent in the [header](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization) of the request.',
  bearer: `
  Bearer authentication gives access to the “bearer of the token” and must be sent in the Authorization header. For example:
  \`\`\`bash
  curl --request POST \\
       --url https://httpbin.org/anything/bearer
       --header 'Authorization: Bearer BEARER_TOKEN'
  \`\`\`
  `,
  cookie: 'Your API Key is being sent as a [cookie](https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies).',
  header: 'Your API Key is sent in the request [header](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers).',
  jwt: "The bearer token's format is JSON Web Token (JWT). Read more at [JWT.io](https://jwt.io/).",
  query:
    'Your API Key is being sent as a query parameter in the [URL](https://developer.mozilla.org/en-US/docs/Web/API/URL).',
} as const;

const InfoBadge = ({ auth, children, hideTooltip, securitySchemeKey }: InfoBadgeProps) => {
  const scheme = children.toLowerCase();
  let tooltipContent = tooltipContentMap[scheme];

  // If we have a scheme that can support displaying dynamic data in the tooltip, let's dig through the users auth data
  // to see if we can use it.
  if (scheme === 'base64') {
    const authParts: string[] = [];
    if (auth && securitySchemeKey in auth && typeof auth[securitySchemeKey] === 'object') {
      const currentAuth = auth[securitySchemeKey];
      const user =
        !!currentAuth && typeof currentAuth === 'object' && typeof currentAuth.user === 'string'
          ? currentAuth.user
          : '';
      const pass =
        !!currentAuth && typeof currentAuth === 'object' && typeof currentAuth.pass === 'string'
          ? currentAuth.pass
          : '';
      if (user || pass) {
        authParts.push(user);
        authParts.push(pass);
      }
    }

    if (authParts.length === 0) {
      authParts.push('hello', 'world');
    }

    tooltipContent = tooltipContent.replace('ENCODED_TOKEN', btoa(authParts.join(':')));
  } else if (scheme === 'bearer') {
    const currentAuth = auth?.[securitySchemeKey];
    if (auth && securitySchemeKey in auth && typeof currentAuth === 'string' && currentAuth.length > 0) {
      tooltipContent = tooltipContent.replace('BEARER_TOKEN', currentAuth);
    } else {
      tooltipContent = tooltipContent.replace('BEARER_TOKEN', '123');
    }
  }

  const renderWithTooltip = !!tooltipContent && !hideTooltip;
  const badge = (opts: { tabIndex?: number } = {}) => (
    <div
      className={`${classes['Info-infoBadge']} ${renderWithTooltip ? classes['Info-infoBadge_tooltip'] : ''}`}
      role={renderWithTooltip ? 'button' : ''}
      {...opts}
    >
      <div>{children}</div>
      {!!renderWithTooltip && (
        <i aria-label="Information" className={`${classes['Info-infoBadge-icon']} icon-info1`} role="img" />
      )}
    </div>
  );

  return renderWithTooltip ? (
    <Tooltip className={classes['Info-tooltip']} content={<RDMD opts={rdmdOpts}>{tooltipContent}</RDMD>} interactive>
      {badge({ tabIndex: 0 })}
    </Tooltip>
  ) : (
    badge()
  );
};

const InfoBadgeParent = React.memo(function InfoBadgeParent({
  auth,
  className,
  hideTooltip,
  security,
}: InfoBadgeParentProps) {
  if (!security) {
    return null;
  }

  return (
    <div className={className}>
      <InfoBadge auth={auth} hideTooltip={hideTooltip} securitySchemeKey={security.security._key}>
        {security.type}
      </InfoBadge>
      {security.type === 'Basic' && (
        <InfoBadge auth={auth} hideTooltip={hideTooltip} securitySchemeKey={security.security._key}>
          base64
        </InfoBadge>
      )}
      {security.type === 'Bearer' && 'bearerFormat' in security.security && !!security.security.bearerFormat && (
        <InfoBadge auth={auth} hideTooltip={hideTooltip} securitySchemeKey={security.security._key}>
          {security.security.bearerFormat}
        </InfoBadge>
      )}
    </div>
  );
});

const InfoTooltip = React.memo(function InfoTooltip({ auth, security, showBadges }: InfoTooltipProps) {
  const { description } = security.security;
  const badgeContent = showBadges && (
    <InfoBadgeParent
      auth={auth}
      className={description ? 'InfoBadge-parent-description' : ''}
      hideTooltip
      security={security}
    />
  );

  const descriptionContent = description && <RDMD opts={rdmdOpts}>{description}</RDMD>;

  return description || showBadges ? (
    <InfoPop>
      <>
        {badgeContent}
        {descriptionContent}
      </>
    </InfoPop>
  ) : null;
});

export { InfoBadgeParent, InfoTooltip };
