import oasToHar from '@readme/oas-to-har';
import { ensureQueryParamUniqueness } from '@readme/server-shared/metrics/unique-query-params'; // eslint-disable-line readme-internal/no-restricted-imports
import { applyProxy } from '@readme/server-shared/metrics-oas'; // eslint-disable-line readme-internal/no-restricted-imports
import fetchHAR from 'fetch-har';
import cloneDeep from 'lodash/cloneDeep';
import Oas from 'oas';
import { METRICS_ENABLED, PROXY_ENABLED } from 'oas/extensions';
import { Operation } from 'oas/operation';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState, useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { ConfigContext } from '@core/context';

import useZeroConfigMetrics from '@routes/Reference/hooks/useZeroConfigMetrics'; // eslint-disable-line no-restricted-imports

import Button from '@ui/Button';
import Icon from '@ui/Icon';

import getHARFromResponse from './lib/get-har-from-response';
import isAuthReady from './lib/is-auth-ready';
import parseResponse from './lib/parse-response';
import classes from './style.module.scss';
import './style.scss';

const APIExecutor = ({
  apiDefinition,
  auth,
  formData,
  harOverride,
  onAuthFailure,
  onResponse,
  operation,
  requestsEnabled,
}) => {
  const config = useContext(ConfigContext);
  const { recordMetrics } = useZeroConfigMetrics(operation);
  const [loading, setLoading] = useState(false);

  const override = useMemo(() => {
    let harCopy;
    // If we're deriving the har/snippet from a previous request...
    if (harOverride) {
      harCopy = cloneDeep(harOverride);
      // Ensure that it's using try-it-now for fetch, if selected
      const proxyEnabled = apiDefinition.getExtension(PROXY_ENABLED, operation);
      harCopy.log = applyProxy(harCopy.log, proxyEnabled);
    }

    return harCopy;
  }, [apiDefinition, operation, harOverride]);

  const har = useMemo(() => {
    const stagedHar =
      override ||
      oasToHar(apiDefinition, operation, structuredClone(formData), auth, {
        /**
         * `@readme/oas-to-har` defaults to **not** funnel requests through our https://try.readme.io
         * CORS proxy. Though we turn this on here if either the `apiDefinition` or `operation` have
         * the `x-readme.proxy-enabled` flag set to `false` then `@readme/oas-to-har` will override
         * our decision.
         *
         * @see {@link https://docs.readme.com/main/docs/openapi-extensions#cors-proxy-enabled}
         */
        proxyUrl: true,
      });

    return ensureQueryParamUniqueness(stagedHar);
  }, [apiDefinition, auth, formData, operation, override]);

  const handleSubmit = useCallback(() => {
    // Send the request if there is either a user-input API key or a selected auth from the OAS.
    if (!isAuthReady(apiDefinition, operation, auth)) {
      onAuthFailure();
      return;
    }

    setLoading(true);

    // This ensures that the browser doesn't automatically add `if-modified-since` or
    // `if-none-match` headers to the request. If we didn't do this, the browser would make the
    // request, cache it, and on future requests return a 200 result even when the real result is a
    // 304.
    let cacheType = 'no-store';

    const { request } = har.log.entries[0];
    if ('headers' in request && request.headers.length) {
      request.headers.forEach(header => {
        if (['if-modified-since', 'if-none-match'].includes(header.name.toLowerCase())) {
          // This ensures that if the API documents the `if-modified-since` or `if-none-match`
          // headers as something they support the user can add a value and then we'll send those
          // along and display the exact response, 200 or 304, without dealing with caching
          cacheType = 'no-cache';
        }
      });
    }

    const headers = {};
    if (apiDefinition.getExtension(PROXY_ENABLED, operation)) {
      /**
       * There's a bug in Firefox and Chrome where they don't respect setting a custom
       * `User-Agent` header on `fetch()` requests so instead to let API metrics know that this
       * request came from the Explorer, we're setting a vendor header instead.
       *
       * We're only setting this if they have the `x-readme.proxy-enabled` flag enabled however
       * because if they have CORS on their own API requests won't go through if they don't
       * explicitly allow this header.
       *
       * @see {@link https://stackoverflow.com/questions/42815087/sending-a-custom-user-agent-string-along-with-my-headers-fetch}
       * @see {@link https://github.com/readmeio/api-explorer/pull/1147}
       */
      headers['x-readme-api-explorer'] = config.releaseVersion;
    }

    fetchHAR(har, {
      init: {
        cache: cacheType,
        headers,
      },

      /**
       * This `files` option in `fetch-har` allows us to ad-hoc send `File` input objects from our
       * schema form instead of sending data URIs that the `FileWidget` generates. The way it works
       * is that this object is keyed by the file name we captured from the input element (like
       * `owlbert.png`) and then when we detect data URIs in our compiled HAR from
       * `@readme/oas-to-har`, and that data URI has a `name=owlbert.png` metadata entry we then
       * send the raw binary from inside the `File` object instead of the data URI.
       *
       * Without this work, depending on however the API we're hitting is set up, files will not
       * always be interpreted as they should be.
       *
       * Seamlessly uploading files is a hard problem that nobody ever talks about.
       */
      files: 'files' in formData && Object.keys(formData.files).length ? formData.files : {},
    }).then(async res => {
      // Only send this log through to zero-config Metrics if this operation has it enabled (the
      // `x-readme.metrics-enabled` extension defaults to true).
      if (apiDefinition.getExtension(METRICS_ENABLED, operation)) {
        // If they've got the SDK set up, we should be sending back a valid log URL once the log is
        // consumed. Since we don't want to create a duplicate entry by way of zero-config metrics
        // we can safely ignore this request if that's the case.
        const consumedHeader = res.headers.get('x-documentation-url');
        if (!consumedHeader) {
          recordMetrics({ har: structuredClone(har), res: res.clone() });
        }
      }

      setLoading(false);

      const parsedResponse = await parseResponse(har, res.clone());
      const responseHAR = getHARFromResponse(har, parsedResponse);

      onResponse(parsedResponse, res.clone(), responseHAR);
    });
  }, [apiDefinition, auth, config.releaseVersion, formData, har, onAuthFailure, onResponse, operation, recordMetrics]);

  if (!requestsEnabled) return null;

  if (harOverride) {
    return (
      <Button
        className={`${classes.TryIt} ${loading ? classes.TryIt_loading : ''} rm-TryIt rm-TryIt_replay`}
        disabled={loading}
        onClick={handleSubmit}
      >
        {!!loading && <div className={classes['TryIt-spinner']} />}
        <Icon name="rotate-ccw" />
        Replay Request
      </Button>
    );
  }

  return (
    <Button
      className={`${classes.TryIt} ${loading ? classes.TryIt_loading : ''} rm-TryIt`}
      disabled={loading}
      onClick={handleSubmit}
    >
      {!!loading && <div className={classes['TryIt-spinner']} />}
      Try It!
    </Button>
  );
};

APIExecutor.propTypes = {
  /**
   * An `Oas` class instance.
   *
   * @see {@link https://npm.im/oas}
   */
  apiDefinition: PropTypes.instanceOf(Oas).isRequired,

  /**
   * This is a keyed object containing authentication credentials for the operation. The keys for
   * this object should match up with the `securityScheme` on the operation you're accessing, and
   * its value should either be a `string`, or an `object` containing `user` and/or `pass` (for
   * Basic auth schemes).
   *
   * This data can be obtained from the `ReferenceStore`.
   */
  auth: PropTypes.object,

  /**
   * This is a keyed object containing formData for your operation. Available keys are: `path`,
   * `query`,` cookie`, `header`, `formData`, and `body`.
   */
  formData: PropTypes.object,

  /**
   * A HAR file which is used during execution. If provided this will override the
   * accompanying OAS.
   *
   * This prop specifically addresses the use case where a user is viewing a particular OAS
   * operation and then clicks on and particular metric and wants to replay a given request.
   *
   * @see {@link http://www.softwareishard.com/blog/har-12-spec/}
   */
  harOverride: PropTypes.object,

  /**
   * Function to call when required auth for the given operation has not been satisfied.
   */
  onAuthFailure: PropTypes.func.isRequired,

  /**
   * Triggered after the API request completes.
   *
   * The `res` that is passed to this contains the following information:
   *
   * - `isBinary` *{Boolean}*: If there was a `Content-Disposition` header containing `attachment`.
   * - `method` *{String}*: HTTP method
   * - `status` *{Integer}*: HTTP status
   * - `requestBody` *{String|null}*
   * - `requestHeaders *{Array}*
   * - `responseBody` *{Object|String}*: Body of the response. If the response has a JSON
   *    `Content-Type` this is a parsed JSON object, otherwise plaintext string.
   * - `responseHeaders` *{Array}*
   * - `timestamp` *{String}*: The timestamp of when the response was received and parsed.
   * - `type` *{String}*: The `Content-Type` of the response.
   * - `url` *{String}*
   */
  onResponse: PropTypes.func.isRequired,

  /**
   * An `Operation` class instance. This can be accessed from `apiDefinition` by running
   * `.operation('/pets', 'get')`.
   */
  operation: PropTypes.instanceOf(Operation).isRequired,

  /**
   * Does the API definition allow making requests?
   * @see {@link https://docs.readme.com/main/docs/openapi-extensions#disable-the-api-explorer}
   */
  requestsEnabled: PropTypes.bool,
};

APIExecutor.defaultProps = {
  auth: {},
  formData: {},
  requestsEnabled: false,
};

export default APIExecutor;
