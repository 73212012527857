import type { User } from 'oas/types';

export enum PURGE_TYPE {
  ACCESS_TOKEN = 'accessTokenRevoked',
  METADATA = 'metadataModified',
  SUBDOMAIN = 'subdomainModified',
}

export const ZERO_CONFIG_CREATOR = 'readme-api-explorer';

type user_permission = 'admin' | 'readonly';

interface DocumentationMetric {
  email: string;
  ip: string;
  userAgent: string;
  userType: user_permission;
  version: string;
}

export interface PageView extends DocumentationMetric {
  path: string;
  type: string;
}

export interface SearchTerm extends DocumentationMetric {
  link: string;
  search: string;
}

export interface PageQualityVote extends DocumentationMetric {
  comments: string;
  path: string;
  type: string;
  vote: number;
}

export interface Group {
  email: string;
  id: string;
  label: string;
}

export interface MetricsUserVariable {
  user:
    | User
    | {
        email: string;
        label?: string;
        name: string;
      };
}
