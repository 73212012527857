// eslint-disable-next-line readme-internal/no-restricted-imports
import { mockMetricsResponse } from '@readme/server-shared/metrics-oas';
import React, { useContext, useCallback, useMemo } from 'react';

import { ProjectContext } from '@core/context';
import { useReferenceStore } from '@core/store';

import { MockHarContext } from '@routes/Reference/context/HARContext';

import type { APIRequestProps } from '@ui/API/Request';
import Request from '@ui/API/Request';

interface RequestContainerProps extends APIRequestProps {
  /** Triggered when errors occur in the "Try It!" button flow. */
  onError: (message: string) => void;

  setResponseHAR: (har: unknown) => void;
}

const RequestContainer: React.FC<RequestContainerProps> = ({ setResponseHAR, ...props }) => {
  const { project } = useContext(ProjectContext);
  const [auth, selectedAuth] = useReferenceStore(s => [s.auth.auth, s.auth.selectedAuth]);
  const { shouldUseMockHars, addHarOverride } = useContext(MockHarContext);

  const { onError } = props;

  // Filter down their entered or JWT auth keys to just what they've selected from the Auth component so that we don't
  // potentially send duplicate security schemes into HAR generation for the request and code snippets.
  // RM-2801, RM-2435
  const currentRequestAuth = useMemo(() => {
    const ret = new Map();
    selectedAuth.forEach(k => {
      if (auth?.[k]) {
        ret.set(k, auth[k]);
      }
    });

    // If none of their selected auth keys exist in their auth configs we should fallback to whatever that auth config
    // is so we don't potentially break anything downstream.
    if (!ret.size) {
      return auth;
    }

    return Object.fromEntries(ret);
  }, [auth, selectedAuth]);

  const onResponse = useCallback(
    /**
     * @param {Object} parsedRes Parsed `res` object returned from `Executor/lib/parse-response`.
     * @param {Object} res Raw `res` object from the `fetch` call.
     * @param {Object} har Compiled HAR of the response.
     */
    async (parsedRes, res, har) => {
      // RM-1362 If the operation has no auth we mimic metrics by using temporary client-side state just to give the user some feedback
      if (shouldUseMockHars) {
        const mockedMetricsResponse = await mockMetricsResponse(project?.subdomain, har, res);
        addHarOverride(mockedMetricsResponse);
      }

      if (parsedRes.status === 401) onError("Sorry, you couldn't be authenticated with those credentials.");
      setResponseHAR(har);
    },
    [addHarOverride, onError, project?.subdomain, setResponseHAR, shouldUseMockHars],
  );

  return <Request {...props} auth={currentRequestAuth} onResponse={onResponse} />;
};

export default RequestContainer;
