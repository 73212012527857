import { useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ThemeContext } from '@Hub/Theme';

import { AppMetaContext, BaseUrlContext, ConfigContext, ProjectContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useFeatureOptions from '@core/hooks/useFeatureOptions';
import useMediaQuery from '@core/hooks/useMediaQuery';
import useUserPermissions from '@core/hooks/useUserPermissions';
import { useProjectStore } from '@core/store';
import capitalize from '@core/utils/capitalize';

import normalizeProjectStoreModules from './normalizeProjectStoreModules';
import normalizeProjectStoreNavList from './normalizeProjectStoreNavList';

const useNavLinks = () => {
  const { project } = useContext(ProjectContext);
  const { planTrial, parent } = project;
  const modules = useProjectStore(s => normalizeProjectStoreModules(s.data.appearance.navigation.links));

  const featureOptions = useFeatureOptions(modules);
  const { isLoggedIn, isAdminUser } = useUserPermissions();
  const { pathname } = useLocation();
  const isSingleProjectGLP = parent?.flags?.singleProjectEnterprise && ['/', ''].includes(pathname);

  let subNav = useProjectStore(s => normalizeProjectStoreNavList(s.data.appearance.navigation.sub_nav));

  // the GLP for single project enterprises should use the nav items in the parent
  if (isSingleProjectGLP) {
    subNav = parent.topnav?.bottom;
  }

  if (!modules) return null;

  // Toggle the `enabled` flag on custom subnav links
  // so they don't show that hidden eyeball icon, and
  // filter for only external URLs and custom pages.
  const extraNavLinks = (subNav || [])
    .map(item => {
      item.enabled = true;
      return item;
    })
    .filter(item => ['url', 'custompage'].includes(item.type));

  const baseNavLinks = Object.keys(featureOptions).reduce((all, key) => {
    // If the landing page, changelogs, or discussions are disabled, they shouldn't appear in the nav
    // regardless of admin or logged in status.
    if (
      (key === 'changelog' && !modules[key]) ||
      (key === 'discuss' && !modules[key]) ||
      (key === 'landing' && !modules[key])
    )
      return all;

    // Logs should only show up if logged in and enabled
    if (key === 'logs' && (!modules[key] || !isLoggedIn)) return all;

    // Don't want to ever show guides if they are on the freelaunch plan
    if (key === 'docs' && planTrial === 'freelaunch') return all;

    if (modules[key] || (isAdminUser && key in modules))
      all.push({
        type: key === 'tutorials' ? 'recipes' : key,
        text: featureOptions[key],
        enabled: modules[key],
      });

    return all;
  }, []);
  return { base: baseNavLinks, extra: extraNavLinks };
};

const useSiblings = ({ baseUrl, lang }) => {
  const { project } = useContext(ProjectContext);
  const { isAdminUser } = useUserPermissions();

  if (!(project?.parent || project?.siblings?.length)) return null;

  // When switching between child projects in an enterprise path,
  // the subdomain and the lang assignment are the only parts that stay constant.
  return project?.siblings?.reduce((all, sib) => {
    const current = baseUrl.split('/')[1] === sib.subdomain;
    const path = !current ? `/${sib.subdomain}/lang-${lang}` : '.';
    // eslint-disable-next-line no-param-reassign
    if (current) sib = project;
    if (sib.is_active || isAdminUser) {
      all.push({ ...sib, active: current, path });
    }
    return all;
  }, []);
};

const useLanguages = ({ lang, version }) => {
  const { project } = useContext(ProjectContext);
  const { pathname } = useLocation();

  // When switching languages, the lang part of the URL is the only part that changes.
  const subdomainPath = project.siblings ? `/${project.subdomain}` : '';
  const versionPath = project.stable.version !== version.version ? `/v${version.version}` : '';
  const enPath = `${subdomainPath}${versionPath}${pathname}`;
  const english = { name: 'Default', path: enPath, code: 'en', active: lang === 'en' };
  return [english].concat(
    project.translate?.languages?.length > 0
      ? project.translate.languages.map(l => {
          const path = `${subdomainPath}/lang-${l.code}${versionPath}${pathname}`;
          return { ...l, active: lang === l.code, path };
        })
      : [],
  );
};

const useVersions = ({ lang, version }) => {
  const { type: pageType } = useContext(AppMetaContext);
  const { project } = useContext(ProjectContext);
  const { pathname } = useLocation();
  const { isEndUser, isLoggedIn } = useUserPermissions();
  const isCustomPage = pageType === 'custompage';

  const versions = useMemo(() => {
    return project.versions
      .filter(v => !v.is_hidden || (isLoggedIn && !isEndUser))
      .map(v => {
        // When switching versions, we return the user to the topmost feature path they're on.
        // E.g. if a user is at /docs/test-page, switching to v2 will take them to /v2/docs.
        const active = version.version === v.version;
        const subdomainPath = project.siblings ? `/${project.subdomain}` : '';
        const langPath = lang !== 'en' ? `/lang-${lang}` : '';
        const versionPath = project.stable.version !== v.version ? `/v${v.version}` : '';
        const resourcePath = `/${pathname.split('/')[1]}`; // i.e. `/docs`, `/tutorials`, etc.
        const basePath = `${subdomainPath}${langPath}${versionPath}`;
        // Preserve the page path when switching versions for custom pages as they are not versioned; otherwise, use the base resource path.
        const nextPath = isCustomPage ? `${basePath}${pathname}` : `${basePath}${resourcePath}`;
        // Use a hash link in an active URL to avoid refreshing the page
        const path = active ? '#' : nextPath;
        return { ...v, active, path };
      });
  }, [project, version, lang, pathname, isEndUser, isCustomPage, isLoggedIn]);

  return versions;
};

const getParentBaseUrl = (isDev, isPr, config, parent) => {
  const isStagingEnabled = parent.flags.staging;
  const customDomain = parent.custom_domain;

  if (isDev) {
    if (!isStagingEnabled) return `http://${parent.subdomain}.readme.local:3000/`;
    return `http://${customDomain}:3000/`;
  }
  if (isPr || customDomain) return `https://${customDomain}/`;

  return `https://${parent.subdomain}.${config.domain}/`;
};

const useDarkMode = () => {
  const { colorMode, setColorMode } = useContext(ThemeContext);
  const colorScheme = useProjectStore(s => s.data.appearance.brand.theme);
  const { project } = useContext(ProjectContext);
  const { parent } = project;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { isClient } = useEnvInfo();

  // use theme if previously set
  const userTheme = (isClient && localStorage.getItem('color-scheme')) || prefersDarkMode;
  const initialStoredTheme = (parent ? parent.appearance.colorScheme === 'system' : colorScheme === 'system')
    ? userTheme
    : null;

  const initialTheme = initialStoredTheme || (parent ? parent.appearance.colorScheme : colorScheme); // need to let parent take precedence over child

  // set theme when toggled
  useEffect(() => {
    // except…when there’s a preview dark mode query param
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const previewDarkMode = params.previewDarkMode;
    if (!previewDarkMode) document.querySelector('[data-color-mode]').setAttribute('data-color-mode', initialTheme);
    localStorage.setItem('color-scheme', initialTheme);
    setColorMode(initialTheme);
  }, [colorMode, setColorMode, initialTheme]);

  return initialTheme;
};

const useLogo = () => {
  const { project } = useContext(ProjectContext);
  const {
    appearance: { logo_white_use: logoWhiteUse },
    first_page: firstPage,
    parent,
    url,
  } = project;

  const { isDev, isPr } = useEnvInfo();
  const config = useContext(ConfigContext);

  const [headerType, mainLogo, darkModeLogo] = useProjectStore(s => [
    s.data.appearance.header.type,
    s.data.appearance.logo.main,
    s.data.appearance.logo.dark_mode,
  ]);
  const isLogoLinked = useProjectStore(s => s.data.appearance.navigation.logo_link === 'homepage');

  const logo = logoWhiteUse && headerType !== 'line' ? darkModeLogo : mainLogo;

  const whiteSrc = darkModeLogo?.url ?? null;
  let src = logo?.url;
  // By default the readme logo when creating a project is blue with a solid background
  // We make it white so it looks nice! ✨ Magic ✨
  // Every comment with "Magic" comes back to bite us, so hello future git blamer
  if (logo?.url?.match(/45785f4-brandmark-blue.svg/) && headerType !== 'line') {
    src = 'https://files.readme.io/97584af-brandmark-white.svg';
  }

  const baseUrl = useContext(BaseUrlContext);

  /**
   * The default behavior is for the logo to be linked to the first page (default: landing page).
   * In this case, we need to add the baseUrl to make sure the entire URL is correct.
   * If the user has linked the logo to a homepage URL they've set, it should be a full URL.
   * Don't add the baseUrl in this case.
   */
  let logoLink = firstPage === 'landing' ? baseUrl : `${baseUrl === '/' ? '' : baseUrl}/${firstPage}`;

  if (parent) logoLink = getParentBaseUrl(isDev, isPr, config, parent);
  if (isLogoLinked && url) logoLink = url;

  if (whiteSrc) return { src, logoLink, whiteSrc };
  return { src, logoLink };
};

const useFeatureFilter = list => {
  const featureOptions = useFeatureOptions();
  const { project } = useContext(ProjectContext);
  const { modules } = project;
  return list
    .filter(({ type }) => modules[type] !== false)
    .map(item => {
      if (item.type in featureOptions) {
        item.text = featureOptions[item.type] || capitalize(item.type);
      }
      return item;
    });
};

export {
  useUserPermissions,
  useNavLinks,
  useSiblings,
  useLanguages,
  useVersions,
  useLogo,
  useFeatureFilter,
  useDarkMode,
};
