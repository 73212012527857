function transformPage(page, endpointSection) {
  return {
    id: page._id,
    isLink: page.type === 'link',
    linkExternal: page.link_external,
    linkUrl: page.link_url,
    method: (page.type !== 'basic' && page?.api?.method.toLowerCase()) || null,
    parentId: endpointSection._id,
    parentTitle: endpointSection.title,
    slug: page.slug,
    title: page.title,
    type: page.type,
  };
}

function traversePageTree(page, endpointSection, transformedPages) {
  let queue = [page];
  while (queue.length) {
    const currentPage = queue.pop();
    queue = queue.concat(currentPage.children);
    const transformedPage = transformPage(currentPage, endpointSection);
    if (!transformedPage.isLink) {
      // currently filters out links, but links could be included if desired
      transformedPages.push(transformedPage);
    }
  }
}

const transformSidebarDataForQuickNav = categories => {
  const flattenedCategories = [];
  // iterate over each section in the endpoint sections
  categories.forEach(category => {
    const flattenedPages = [];
    // iterate over each top-level (root level) pages in each section
    category.pages.forEach(page => {
      traversePageTree(page, category, flattenedPages);
    });

    // add section pages to the section array
    if (flattenedPages.length) {
      flattenedCategories.push({
        id: category._id,
        children: flattenedPages,
        title: category.title,
      });
    }
  });

  return flattenedCategories;
};

export default transformSidebarDataForQuickNav;
