import React, { useState } from 'react';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

/**
 * Returns the state and toggle for showing/hiding a password.
 */
export default function useShowPasswordToggle() {
  const [showPassword, setShowPassword] = useState(true);
  const ShowPasswordToggle = (
    <Tooltip content={showPassword ? 'Hide' : 'Show'}>
      <Button
        aria-label={showPassword ? 'Hide' : 'Show'}
        className={`rm-APIAuth-lock ${classes['APIAuth-lock']}`}
        kind="minimum"
        onClick={() => setShowPassword(!showPassword)}
        size="xs"
        text
      >
        <Icon name={showPassword ? 'lock' : 'unlock'} />
      </Button>
    </Tooltip>
  );

  return { ShowPasswordToggle, showPassword };
}
