import type { MetricsUserVariable, Group } from './types';
import type { Entry } from 'har-format';
import type { Response, Headers } from 'node-fetch';
import type { Operation } from 'oas/operation';

import { getGroupId } from './getGroupId';
import { maskCredential } from './mask-credential';
import { ZERO_CONFIG_CREATOR } from './types';

export const getRequestGroup = (variables: MetricsUserVariable, groupId?: string, operation?: Operation) => {
  if (!Object.keys(variables?.user || {}).length) return { id: groupId ?? '', label: '', email: '' };

  const user = variables.user;
  const apiKey = (groupId || getGroupId(user, operation)) as string;
  const label = (user.label || user.name || '') as string;
  const email = user.email as string;

  return { id: maskCredential(apiKey), label, email };
};

export const transformHeaders = (originalHeaders: Headers) => {
  if (!originalHeaders) return [];

  const headers: { name: string; value: string }[] = [];

  // reformat headers for expected format by metrics
  for (const entry of originalHeaders.entries()) {
    if (entry.length === 2) {
      const [name, value] = entry;
      headers.push({ name, value });
    }
  }

  return headers;
};

const createApiLog = (
  logResponse: Response,
  responseBody: string,
  harEntry: Entry,
  group: Group,
  subdomain: string,
) => {
  const creator = { name: ZERO_CONFIG_CREATOR, version: '1.0' };
  const entries = [
    {
      time: '1',
      startedDateTime: new Date(),
      request: harEntry.request,
      response: {
        status: logResponse.status,
        statusText: logResponse.statusText,
        headers: transformHeaders(logResponse.headers),
        content: {
          size: responseBody.length,
          text: responseBody,
        },
      },
    },
  ];

  return {
    subdomain,
    log: {
      development: false,
      group,
      request: { log: { creator, entries } },
      _version: 3, // Version 3 of the request body includes credential hashing
    },
  };
};

export const processTryItRequest = async (subdomain: string, harEntry: Entry, group: Group, logResponse: Response) => {
  let body = '';

  try {
    body = await logResponse.text();
    return createApiLog(logResponse, body, harEntry, group, subdomain);
  } catch (e) {
    return createApiLog(logResponse, body, harEntry, group, subdomain);
  }
};
