import type { ProjectClientSide } from '@readme/backend/models/project/types';
import type Oas from 'oas';
import type { Operation } from 'oas/operation';

import React, { useMemo, useRef, useState } from 'react';

import type { ReferenceStore } from '@core/store';

import '@ui/API/Group.scss';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu from '@ui/Menu';
import MenuHeader from '@ui/Menu/Header';
import MenuItem from '@ui/Menu/Item';

import './style.scss';

interface Props {
  oas: Oas;
  operation: Operation;
  project?: ProjectClientSide;
  readOnly?: boolean;
  servers: ReferenceStore['form']['schemaEditor']['data']['server'];
  updateServers: ReferenceStore['form']['updateServer'];
  varOpts?: Partial<typeof Dropdown>;
}

const APIServer = ({ oas, operation, project, readOnly, servers, updateServers, varOpts }: Props) => {
  const [activeVar, setActiveVar] = useState<string | null>(null);
  const formEl = useRef<HTMLFormElement>(null);

  let selected = 0;
  let variables: NonNullable<ReferenceStore['form']['schemaEditor']['data']['server']>['variables'];
  if (servers) {
    [selected, variables] = [servers.selected, servers.variables];
  }

  /**
   * Contains a hash map of all project custom variables defined by the user in
   * `[variableName]: defaultValue` pairs. These defaults take precedent over
   * other server variable defaults defined in the OAS spec.
   */
  const customVariables = useMemo(() => {
    if (!project?.variableDefaults) return {};
    return project.variableDefaults.reduce((defaults, variableDefault) => {
      if (!variableDefault) return defaults;

      // We only want to look for "custom" variables that are manually set by
      // customers as an alternative to server variable defaults. Custom
      // variables in a project won't have a source value.
      const isCustomVariable = variableDefault.source === '';
      if (!isCustomVariable) return defaults;

      return {
        ...defaults,
        [variableDefault.name as string]: variableDefault.default,
      };
    }, {});
  }, [project?.variableDefaults]);

  function onChange({ target: { name, value } }) {
    const newVariables = { ...variables };

    if (typeof value === 'undefined') {
      delete newVariables[name];
    } else {
      newVariables[name] = value;
    }

    updateServers({ selected, variables: newVariables });
  }

  function setSelected(newSelected) {
    updateServers({
      selected: newSelected,
      variables: {
        ...oas.defaultVariables(newSelected),
        ...customVariables,
      },
    });
  }

  const handleValidation = e => e.currentTarget.form.reportValidity();
  const splitUrl = oas.splitUrl(selected);
  const serverVariables = splitUrl.filter(({ type }) => type === 'variable');

  function Reset({ fieldName }: { fieldName: string }) {
    const oasDefaultValue = oas.defaultVariables(selected)[fieldName];
    const customDefaultValue = customVariables[fieldName];

    // Custom variable defaults take precedent over OAS server variable defaults.
    const defaultValue = customDefaultValue ?? oasDefaultValue;
    if (variables && defaultValue === variables[fieldName]) {
      return null;
    }

    return (
      <Flex
        align="center"
        className="ServerVars-form-reset"
        gap="xs"
        onClick={() => onChange({ target: { name: fieldName, value: defaultValue } })}
        tag="button"
        type="button"
      >
        <Icon isFont name="icon-rotate-cw" />
        Reset to Default
      </Flex>
    );
  }

  const variablesForm = (
    <Menu>
      <form ref={formEl} className="ServerVars-form">
        {serverVariables.map(part => {
          // There's currently a bug in Firefox, where
          // when you click the "reset to default" button
          // it does not work on first click due to setActiveVar
          // being called and that triggering a re-render so
          // you have to click again.
          //
          // In chrome this can be solved by using `e.relatedTarget`
          // which is equal to the <button> but on firefox
          // it is the tippy element
          //
          // If we want this to work in Firefox we can use a
          // setTimeout or do something with a useEffect hook
          //
          // https://github.com/facebook/react/issues/4210
          function onBlur(e) {
            if (e.relatedTarget) e.relatedTarget.click();
            setActiveVar(null);
          }

          return (
            <MenuItem
              key={part.key}
              className="ServerVars-popover ServerVars-popover_vars"
              focusable={false}
              TagName="div"
            >
              <FormGroup align="stretch" description={part.description} size="sm">
                <label className="Flex Flex_start FormGroup-label" htmlFor={part.value}>
                  {part.value}
                  <Reset fieldName={part.value} />
                </label>
                {Array.isArray(part.enum) ? (
                  <select
                    className="Select Select_sm"
                    disabled={readOnly}
                    id={part.value}
                    name={part.value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={() => setActiveVar(part.value)}
                    required
                    value={variables?.[part.value] as string}
                  >
                    {part.enum.map(value => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <Input
                    aria-label="Server variable"
                    className="ServerVars-input"
                    disabled={readOnly}
                    id={part.value}
                    name={part.value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={e => {
                      handleValidation(e);
                      setActiveVar(part.value);
                    }}
                    required
                    size="sm"
                    spellCheck="false"
                    value={variables?.[part.value] as string}
                  />
                )}
              </FormGroup>
            </MenuItem>
          );
        })}
      </form>
    </Menu>
  );

  // @todo build this into a `.getServers` method in the OAS library.
  const oasServers = oas.api.servers || [{ url: 'https://example.com' }];

  const variablesButton = (
    <button className="InputGroup-input ServerVars-url" data-testid="serverurl" disabled={serverVariables.length === 0}>
      {
        // eslint-disable-next-line array-callback-return, consistent-return
        splitUrl.map(part => {
          // eslint-disable-next-line default-case
          switch (part.type) {
            case 'text':
              return <span key={part.key}>{part.value}</span>;

            case 'variable':
              return (
                <span
                  key={part.key}
                  className={`ServerVars-url-var ${!variables?.[part.value] ? 'ServerVars-url-var_invalid' : ''} ${
                    activeVar === part.value ? 'ServerVars-url-var_highlight' : ''
                  }`}
                  data-testid={`variable-${part.value}`}
                >
                  {variables?.[part.value] || part.value}
                </span>
              );
          }
        })
      }
      <span className="ServerText-endpoint" data-testid={'url-endpoint'}>
        {operation.path}
      </span>
    </button>
  );

  return (
    <div className="ServerVars InputGroup">
      <Dropdown align="bottom" justify="start" offset={[-1, 5]} sticky trigger="click">
        <button className="InputGroup-button" disabled={oasServers.length === 1} type="button">
          <span>Base URL</span>
          {oasServers.length > 1 && <span className="icon-chevron-down" />}
        </button>
        <Menu className="Menu-popover" role="tablist">
          <MenuHeader>Servers</MenuHeader>
          {oasServers.map((server, i) => {
            const selectedServer = i === selected;
            return (
              <MenuItem
                key={server.url}
                active={selectedServer}
                aria-selected={selectedServer}
                description={server.description}
                onClick={() => setSelected(i)}
                onKeyPress={() => setSelected(i)}
                role="tab"
              >
                <span>{server.url}</span>
              </MenuItem>
            );
          })}
        </Menu>
      </Dropdown>
      {serverVariables.length === 0 ? (
        variablesButton
      ) : (
        <Dropdown
          align="bottom"
          className="ServerVars-url-wrapper"
          justify="start"
          offset={[0, 5]}
          onBlur={() => {
            if (!formEl.current) return true;
            formEl.current.reportValidity();
            return formEl.current.checkValidity();
          }}
          sticky
          trigger="click"
          {...varOpts}
        >
          {variablesButton}
          {variablesForm}
        </Dropdown>
      )}
    </div>
  );
};

export default React.memo(APIServer);
