import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Graphic from '@ui/Graphic';

import classes from './style.module.scss';

interface Props {
  className?: string;
  language: string;
}

const LanguageIcon: React.FC<Props> = ({ className = '', language }) => {
  const bem = useClassy(classes, 'LanguagePicker');

  // We've reworked the cURL language to be "Shell", but the old icon for cURL still applies here.
  const iconLanguage = language === 'shell' ? 'curl' : language;

  if (language === 'aws') {
    return (
      <Graphic
        className={className}
        name="aws"
        size="sm"
        wrapperClassName={bem('-languageIcon-aws', 'icon', 'icon-aws')}
      />
    );
  }

  if (language === 'json') {
    return (
      <Graphic
        className={className}
        name="json"
        size="sm"
        wrapperClassName={bem('-languageIcon-json', 'icon', 'icon-json')}
      />
    );
  }

  if (language === 'dotnet') {
    return (
      <span className="icon-dotnet" style={{ background: '#512bd4' }}>
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
        <span className="path5"></span>
      </span>
    );
  }

  // We need a `path` per color used for font icons (we currently use 8 for Java). If an icon just
  // needs fewer than 8 colors, those spans will still be there, but not affect how it renders.
  return (
    <i className={`${className} icon-${iconLanguage}`}>
      <span className="path1" />
      <span className="path2" />
      <span className="path3" />
      <span className="path4" />
      <span className="path5" />
      <span className="path6" />
      <span className="path7" />
      <span className="path8" />
    </i>
  );
};

export default LanguageIcon;
